<template>
  <div>
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching customer data
      </h4>
      <div class="alert-body">
        No customer found with this customer id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'customers' }"
        >
          customers list
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <content-menu :items="menuItems" />
      <b-row>
        <b-col
          md="9"
        >
          <b-card>
            <b-row>

              <!-- User Info: Left col -->
              <b-col
                cols="12"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :src="userData.avatar"
                    :text="avatarText(`${userData.firstName} ${userData.lastName}`)"
                    :variant="`light-info`"
                    size="104px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ userData.firstName }} {{ userData.lastName }}
                      </h4>
                      <span class="card-text">{{ userData.email }}</span>
                    </div>
                    <div class="d-flex flex-wrap">
                      <!--                  <b-button-->
                      <!--                    :to="{ name: 'apps-users-edit', params: { id: userData.id } }"-->
                      <!--                    variant="primary"-->
                      <!--                  >-->
                      <!--                    Edit-->
                      <!--                  </b-button>-->
                      <!--                  <b-button-->
                      <!--                    variant="outline-danger"-->
                      <!--                    class="ml-1"-->
                      <!--                  >-->
                      <!--                    Delete-->
                      <!--                  </b-button>-->
                    </div>
                  </div>
                </div>
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="6"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="UserIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Date of birth</span>
                    </th>
                    <td class="pb-50">
                      {{ userData.dateOfBirth | formatDate('DD-MM-YYYY') }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="CheckIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Adres</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ userData.address }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="StarIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Zipcode</span>
                    </th>
                    <td class="pb-50 text-capitalize">
                      {{ userData.zipcode }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="FlagIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Stad</span>
                    </th>
                    <td class="pb-50">
                      {{ userData.city }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <feather-icon
                        icon="PhoneIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold">Telefoonnummer</span>
                    </th>
                    <td>
                      {{ userData.phoneNumber }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import {
  BRow, BCol, BAvatar, BAlert, BCard,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ContentMenu from '@/views/components/ContentMenu.vue'
import Api from '@/libs/api'
import customerStoreModule from './customerStoreModule'

export default {
  components: {
    ContentMenu,
    BRow,
    BCol,
    BAvatar,
    BAlert,
    BCard,
  },
  data() {
    return {
      menuItems: [
        {
          title: 'Overzicht',
          route: { name: 'customers-view', params: { id: this.$route.params.id } },
        },
        {
          title: 'Facturen',
          route: { name: 'customers' },
        },
        {
          title: 'Afspraken',
          route: { name: 'customers' },
        },
        {
          title: 'Geschiedenis',
          route: { name: 'customers' },
        },
      ],
    }
  },
  setup() {
    const userData = ref(null)
    const USER_CUSTOMER_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_CUSTOMER_STORE_MODULE_NAME)) store.registerModule(USER_CUSTOMER_STORE_MODULE_NAME, customerStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(USER_CUSTOMER_STORE_MODULE_NAME)
    })

    Api.get('customers', router.currentRoute.params.id)
      .then(response => { userData.value = response })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    return {
      avatarText,
      userData,
    }
  },
}
</script>

<style>

</style>
