<template>
  <b-card
    class="profile-header mb-2"
    img-top
    body-class="p-0"
  >
    <div class="profile-header-nav">
      <b-navbar
        toggleable="md"
        type=""
      >
        <b-navbar-toggle
          class="ml-auto"
          target="nav-text-collapse"
        >
          <feather-icon
            icon="AlignJustifyIcon"
            size="21"
          />
        </b-navbar-toggle>

        <b-collapse
          id="nav-text-collapse"
          is-nav
        >
          <b-tabs
            pills
            class="profile-tabs mt-1 mt-md-0"
            nav-class="mb-0"
          >
            <template #tabs-start>
              <b-nav-item
                v-for="(item, index) in items"
                :id="`menu-item-${index}`"
                :key="index"
                :to="item.route"
                role="presentation"
                class="font-weight-bold"
                :active="$route.name === item.route.name"
              >
                <span class="d-none d-md-block">{{ item.title }}</span>
                <feather-icon
                  v-if="item.icon"
                  :icon="item.icon"
                  class="d-block d-md-none"
                />
              </b-nav-item>
            </template>

            <template #tabs-end>
              <b-button
                v-if="edit"
                variant="primary"
                class="ml-auto"
              >
                <feather-icon
                  icon="EditIcon"
                  class="mr-1"
                />
                <span class="font-weight-bold">Edit</span>
              </b-button>
            </template>
          </b-tabs>

        </b-collapse>
      </b-navbar>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BNavbar, BNavbarToggle, BCollapse, BTabs, BTab, BNavItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
  },
  directives: {
    Ripple,
  },
  props: {
    edit: {
      type: Object,
      required: false,
      default: () => {},
    },
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/include';
@include media-breakpoint-up(md) {
  // navbar tabs pills
  .profile-header-nav {
    .profile-tabs {
      width: 100%;
    }
  }
  .tab-content .btn {
    width: 200px;
    height: 200px;
    margin-right: 20px;
  }
}
</style>
